.carousel {
  .actions-controller__wrapper {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  ::-webkit-scrollbar {
    border-radius: 15px;
    width: 2px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff17;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f46c;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #aaa !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb !important;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

.carousel-item {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  .carousel-item-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0.5;
    background: #000;
  }
  .carousel-caption {
    max-height: calc(100% - 2.5rem);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    height: 100%;
    overflow: auto;

    [data-contents='true'],
    .actions-controller__wrapper {
      // height: 100%;
      overflow: auto;
    }
    .actions-controller__wrapper.rich-text__wrapper {
      .add-button-green {
        display: none !important;
      }
    }
  }

  &,
  & .carousel-item-overlay,
  & img {
    border-radius: 15px;
  }
}

.overlay-while-dragging {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
}
