.contact-form__wrapper {
  .text-input {
    .form-control {
      height: 46px;
      padding-block: 0.6em;
    }
    .input-group-text {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: #fff;
      height: 100%;
      max-width: 42px;

      .icon-provider__emoji {
        transform: translateX(-2px);
      }
    }

    .form-control {
      border-radius: 0 5px 5px 0 !important;
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
      height: 46px;
    }

    .flag-dropdown {
      width: 42px;
      background-color: #fff;
    }

    .selected-flag,
    .flag-dropdown {
      border-radius: 5px 0 0 5px !important;
    }

    &.is-valid,
    &.is-invalid {
      input {
        padding-right: calc(1.5em + 0.75rem);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }
    }

    &.is-valid {
      input {
        border-color: #198754;
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
      }
    }
  }

  .is-invalid {
    input {
      border-color: #dc3545;
      // background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    }
    .form-check-label {
      color: #dc3545 !important;
    }
  }

  .site-form-item-icon {
    height: 32px;
  }

  .contact-form__inner-wrapper {
    input {
      font-size: 14px;
    }
  }

  .form-check-label {
    font-weight: 300;
    font-size: 0.9em;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.is-invalid {
  animation: shake 1s;
}
