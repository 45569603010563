.button-component {
  border: 0px;
  border-radius: 100em;
  padding: 1em 2em;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 20%);
  margin: 0.65rem 0;

  &__wrapper {
    padding-top: 0;
  }

  &:focus {
    border: 0px;
    box-shadow: none;
  }

  [contenteditable='true']:empty:before {
    content: attr(placeholder);
  }

  @include defaultOpacityHover;
}
