body {
  overflow-x: hidden;
}

a {
  cursor: pointer;

  &,
  &:hover {
    transition: ease opacity 0.5s;
  }

  &:hover {
    opacity: 0.75;
  }
}

.display-block-important {
  display: block !important;
}

.link-style {
  text-decoration: underline;
  color: $primary-color;
  cursor: pointer;
}
