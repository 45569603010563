@mixin fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
}

@mixin horizontalCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

@mixin horizontalAndVerticalCenter {
  @include horizontalCenter;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin defaultOpacityHover {
  &,
  &:hover {
    transition: 0.25s ease-in-out opacity;
  }

  &:hover {
    opacity: 0.9;
  }
}
