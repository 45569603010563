/* Utils */
.actions-controller__wrapper.p-4 {
  padding: 0.8rem !important;
}

/* Text */
.rich-text__wrapper {
  h1,
  h2 {
    font-size: 0.7em;
  }

  h3 {
    font-size: 0.8em;
  }
}

/* Container */
$paddingSMobile: 6px;
$paddingMMobile: 36px;
$paddingLMobile: 72px;

.container {
  &__padding {
    &-s {
      &-top {
        padding-top: $paddingSMobile;
      }

      &-bottom {
        padding-bottom: $paddingSMobile;
      }
    }

    &-m {
      &-top {
        padding-top: $paddingMMobile;
      }

      &-bottom {
        padding-bottom: $paddingMMobile;
      }
    }

    &-l {
      &-top {
        padding-top: $paddingLMobile;
      }

      &-bottom {
        padding-bottom: $paddingLMobile;
      }
    }
  }

  &__wrapper {
    .row {
      margin-bottom: 0;
    }
  }
}

/* Grid */
.column-parent-wrapper {
  padding-bottom: 1.15rem !important;
  padding-top: 1.15rem !important;
}

/* Button */
.button-component {
  width: 100%;
}

.element-appender__inner {
  .choice-wrapper {
    .icon-provider {
      font-size: 2.5em !important;
    }

    .choice-inner-wrapper {
      min-height: 0;

      &.p-4 {
        padding: 1rem 1.5rem !important;
      }
    }
  }
}

.video-wrapper {
  &__disabled-wrapper {
    p {
      font-size: 0.75em;
    }

    &__icon {
      display: none;
    }

    .btn-primary {
      font-size: 0.75em;
    }
  }
}

@media screen and (max-width: 415px) {
  .element-appender__inner {
    .choice-wrapper {
      .icon-provider {
        font-size: 2em !important;
      }

      .choice-inner-wrapper {
        &.p-4 {
          padding: 0.75rem 1rem !important;
        }
      }
    }
  }
}

.choice-container-hoc {
  padding: 0;
}

/* Footer */
.footer-wrapper {
  align-items: initial !important;
  justify-content: initial !important;

  &__link,
  .d-flex {
    display: block !important;
  }

  &__link,
  &__branding a {
    margin-bottom: 0.5em;
  }

  .fw-lighter {
    display: none;
  }
}

.icon__element {
  font-size: 0.75em;
}
