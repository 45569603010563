.container-md {
  max-width: 1000px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.max-width {
  &__700px {
    &-centered {
      max-width: 700px;
      width: 100%;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__1000px {
    &-centered {
      max-width: 1000px;
      width: 100%;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__450px {
    &-centered {
      max-width: 450px;
      width: 100%;
      display: table;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
