.calendar {
  background: #fff;
  border-radius: 6px;
  color: $primary-text-color;
  box-shadow: $primary-box-shadow;
  text-align: left;

  &__dummy {
    padding: 2px;
  }
}

.alert-danger.show h3 {
  margin-top: 0;
  margin-bottom: 0.45em;
  color: #721c24 !important;
}

.timeslots {
  overflow-y: scroll;
  overflow-x: hidden;

  height: 320px;

  position: relative;

  box-shadow: inset 0px 11px 15px -10px rgba(0, 0, 0, 0.125),
    inset 0px -11px 15px -10px rgba(0, 0, 0, 0.125);

  //Chrome, Safari, Opera
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  //Firefox
  scrollbar-width: none;

  //Internet Explorer, Edge
  -ms-overflow-style: none;

  &__message {
    text-align: center;
    height: 100%;
    padding: 2.75em 0.75em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h3 {
      font-size: 1.2em;
      margin-top: 0;
      margin-bottom: 0.15em;
    }

    i {
      font-size: 2.5em;
      display: block;
      margin-bottom: 0.35em;
      line-height: 0;
    }

    span {
      color: $secondary-text-color;
      font-weight: 300;
    }
  }

  & .icon-link {
    text-align: center;

    & > * {
      color: #333 !important;
    }
  }
}

.timeslot-wrapper {
  margin: 2em 0 2.75em;

  &__inner {
    display: flex;
    flex-flow: row wrap;
  }

  //   @include breakpoint("max_sm") {
  margin: 2em 1em 1.75em;
  //   }

  & .time-divider {
    position: relative;
    text-align: center;

    & span {
      transform: translateY(-55%);
      display: block;
      background: #fff;
      width: 110px;
      line-height: initial;
      margin: 0 auto;
      color: #999;
      font-size: 0.75em;
    }

    & .line {
      height: 1px;
      background: #eee;
    }
  }

  .timeslot {
    border-radius: 5px;
    padding: 5px 10px;
    border: 2px solid $secondary-color;
    margin: 8px;
    cursor: pointer;
    width: calc(33.333% - 16px);
    text-align: center;
    color: $secondary-color;

    &:hover {
      background: #fff;
    }

    & .percent-circle,
    & .percent-number-circle {
      transition: ease all 0.5s;
      position: absolute;
      background: $primary-color;
      color: $color-high-contrast;
      width: 22px;
      height: 22px;
      line-height: 21px;
      right: -7px;
      top: -7px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #fff;
      transform: scale(1);
    }

    & .percent-number-circle {
      width: 35px;
      height: 35px;
      line-height: 32px;
      right: -17px;
      top: -17px;
      transform: scale(0);
    }

    &:hover {
      & .percent-number-circle,
      & .percent-circle {
        transition: ease all 0.5s;
      }

      & .percent-number-circle {
        transform: scale(1);
      }

      & .percent-circle {
        transform: scale(0);
      }
    }
  }
}

.day-selector__navigation-mobile {
  display: flex;
  align-items: center;
  padding: 0.5em 0.35em;

  & > * {
    flex: 1;
    line-height: 25px;
  }

  & .day-selector__left {
    text-align: left;
    margin: 0 !important;
  }

  & .day-selector__right {
    text-align: right;
    margin: 0 !important;
  }

  & .month {
    text-align: center;
  }
}

.day-selector {
  position: relative;
  padding: 1em 0;
  display: initial;

  & .day-selector__days {
    margin: 0 auto;
    display: flex;
    padding-inline-start: 0;
    margin-bottom: 0.85em;
    padding: 0 1em;
    font-size: 0.75em;

    & li {
      cursor: pointer;
      display: inline-block;
      text-align: center;
      padding: 0 3em;
      color: $primary-text-color;

      // @include breakpoint("max_sm"){
      list-style: none;
      padding: 0 0.65em;
      flex: 1;
      // }

      & > * {
        display: block;
      }

      &:hover {
        color: $primary-text-color;
      }

      & .dom {
        font-weight: 700;
        width: 26px;
        height: 26px;
        line-height: 25px;
        border-radius: 50%;
        margin: 2px 0;
        margin: 0 auto;
      }

      &.active {
        color: $primary-text-color;

        & .dom {
          background: $primary-color;
          color: #fff;
        }
      }

      &:hover {
        .dom {
          color: #fff;
        }
      }

      &:focus,
      &:active {
        .dom {
          opacity: 1 !important;
        }
      }

      &.past,
      &.past:hover {
        cursor: not-allowed;
        color: $default-grey;

        & .dom {
          text-decoration: line-through;
          border: none !important;
          background: none !important;
          color: $default-grey;
        }
      }
    }
  }

  & i {
    &,
    &:hover {
      transition: ease all 0.5s;
    }

    cursor: pointer;
    font-size: 2.5em;
    position: absolute;
    top: 50%;
    height: 32px;
    width: initial;
    line-height: 32px;
    transform: translateY(-50%);

    //   @include breakpoint("max_sm"){
    position: initial;
    transform: initial;
    font-size: 1.5em;
    height: initial;
    line-height: initial;
    //   }

    &.left {
      left: 25px;

      &:hover {
        left: 22px;
      }
    }

    &.right {
      right: 25px;

      &:hover {
        right: 22px;
      }
    }
  }
}
