.linktree {
  margin: 0 !important;
  text-align: center;
  list-style: none;

  &__link {
    a {
      color: #fff !important;
      font-size: 2.5em;
      font-weight: 300;
      text-decoration: initial;

      &:hover {
        @include defaultOpacityHover;
      }
    }
  }
}
