.spinner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 20;
}

.loader-center {
  position: absolute;
  z-index: 25;
  left: 50%;
  top: 50%;
  margin-left: -$default-left-right-padding;
  margin-top: -$default-left-right-padding;
}
