.popover-full {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: initial;
  position: fixed;
  display: flex;
  align-items: center;
  background: $primary-gradient;
  padding: $default-top-bottom-padding 0;

  &__close {
    position: fixed;
    top: 20px;
    right: 15px;
    font-size: 2em;
    line-height: 0;
    color: #fff;
    cursor: pointer;
  }

  &__content {
    width: 100%;
    max-width: 700px;
    padding: 15px;
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,
    b,
    label {
      color: #fff;
    }
  }

  &__trigger_wrapper {
    transition: ease 0.75s height;
  }

  &__trigger {
    cursor: pointer;
  }
}

.block-interaction {
  &__overlay {
    @include fullscreen();
    z-index: 400;
    background: rgba(0, 0, 0, 0.5);
  }
}

.popover-open {
  .cookie-banner {
    left: -3000px;
  }
  .block-interaction__overlay {
    display: none;
  }
}
