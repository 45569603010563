.cookie-banner {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  background: #fff;
  box-sizing: border-box;
  padding: 10px $default-left-right-padding;
  box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.1);
  z-index: 500;
  border-radius: 10px;
  margin: 15px;

  &__inner {
    position: relative;
  }

  &__text {
    font-size: 0.75em;
    margin-bottom: 0;
  }

  &__close {
    position: absolute;
    right: -$default-left-right-padding;
    top: 0;
    color: $primary-color;
    font-size: 1.5em;
    cursor: pointer;
    line-height: 0;
  }

  &__message {
    padding-right: 30px;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
  }

  &__cookie {
    width: 40px;
    float: left;
    margin-right: 0.65em;
  }

  &__submit {
    font-size: 0.8em;
    background: $primary-color;
    padding: 0.5em 0.5em;
    margin-top: 1em;
    display: block;
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
    color: #fff;

    i {
      margin-right: 0.25em;
    }
  }

  @media (min-width: 620px) {
    bottom: 0;
    transform: initial;
    top: initial;
    max-width: 500px;
  }
}

.app .cookie-banner {
  p {
    color: #777 !important;
  }
}

.cookie-settings {
  overflow-y: scroll;
  align-items: initial !important;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  header {
    margin-bottom: 2em;
  }

  h4 {
    margin-bottom: 1rem;
  }

  p {
    color: #fff;
  }

  a {
    color: #fff !important;
    text-decoration: underline !important;
    cursor: pointer;
  }

  &__buttons {
    margin-top: 2em;

    .btn.btn-link.btn-block {
      margin-top: 0.25em;
      color: #fff;
      font-weight: 300;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #28a745 !important;
    background-color: #28a745 !important;
  }

  &__open-settings,
  &__reject-all {
    border: 1px solid $default-grey;
    border-radius: 50px;
  }

  &__reject-all,
  &__open-settings {
    color: $secondary-text-color;
    font-size: 0.75em;
    text-align: center;
    display: block;
    font-weight: 300;
    padding: 0.5em 0.5em;
    margin-top: 0.5em;
    cursor: pointer;
  }
}

.submissionpopup {
  @media (max-width: 500px) {
    &__accept-all,
    &__accept-checked {
      &.btn {
        display: block !important;
        width: 100%;
        margin: 0 !important;
      }
    }

    &__accept-checked {
      order: 5;
    }
  }
}
