.img-element {
  &__wrapper {
    padding: 2px;
    position: relative;
    .spinner__wrapper {
      position: absolute;
      top: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgb(180, 200, 209);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: larger;
      border-radius: 15px;
    }

    &__loading {
      height: 500px;
    }
  }
}
