.account-needs-to-pay-info {
  &__inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 800;
    text-align: center;
    width: 100%;

    &__content {
      max-width: 800px;
      margin: 0 auto;
      padding: 0 10px;

      .meetovo-logo {
        max-width: 280px;
        margin-bottom: 20px;
      }

      h3,
      p,
      a {
        color: #fff;
      }

      a {
        font-weight: 700;
      }

      @media (max-width: 630px) {
        .meetovo-logo {
          max-width: 220px;
          margin-bottom: 15px;
        }

        h3 {
          font-size: 1.5em;
        }
      }
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.6) !important;
    backdrop-filter: blur(3px);
    z-index: 700 !important;
  }
}
